<template>
  <div>
    <NavTitle class="mb16" title="报名单赔付">
      <template #right>
        <div class="flex items-center">
          <el-date-picker
            class="mr10"
            v-model="screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch">
          </el-date-picker>
          <div class="input-row">
            <el-input class="input" placeholder="请输入商户ID/手机号" clearable v-model="seachWord" @change="handleSearch"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"  @click="handleSearch"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="statics ml20 mr20 mb20" v-if="claimStatics">
      <div>
        <div>申请通过率</div>
        <div class="num">{{claimStatics.acceptRate}}</div>
      </div>
      <div>
        <div>申请通过次数</div>
        <div class="num">{{claimStatics.acceptTimes}}</div>
      </div>
      <div>
        <div>累计申请次数</div>
        <div class="num">{{claimStatics.applyTimes}}</div>
      </div>
      <div>
        <div>累计申请赔付报名单数量</div>
        <div class="num">{{claimStatics.totalApplyAmount}}</div>
      </div>
      <div>
        <div>累计赔付报名单数量</div>
        <div class="num">{{claimStatics.totalClaimAmount}}</div>
      </div>
    </div>
    <div class="ml20 mr20">
      <el-menu  :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index" style="position:relative;">
          {{item.text}}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="ml20 mr20">
      <el-row :gutter="20" v-if="claimList && claimList.length > 0">
        <el-col :span="12" class="mt16" v-for="(item, index) in claimList" :key="index">
          <div class="paid-item">
            <div class="title-row">
              <div class="flex items-center">
                <LoadImage class="avatar" :src="item.reporterLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
                <span class="bold">{{item.reporterName}}</span>
              </div>
              <span class="time">{{item.gmtCreate}}</span>
            </div>
            <div class="flex between-center">
              <div>
                <div class="flex items-center">
                  <span>{{item.name}}</span>
                  <div class="enter-arrow" @click="$refs.postDetailRef.open({pid: item.pid})">
                    <span>报名职位：{{item.postTitle}}</span>
                    <img src="@/assets/images/arrow-right-theme.png">
                  </div>
                </div>
                <div class="info-row">
                  <span>{{item.gender}}</span>
                  <span class="gap-line">|</span>
                  <span>{{item.age}}</span>
                  <span class="gap-line">|</span>
                  <span>{{item.profType}}</span>
                </div>
              </div>
              <div class="img-list">
                <LoadImage class="img" v-for="(url, index) in item.evidence" :key="index" :src="url" preview></LoadImage>
              </div>
            </div>
            <div class="flex between-end mt10">
              <div>
                <div class="mb8">用户手机号：{{item.userMobile}}</div>
                <div class="mb8">投诉类型：{{item.reportTypeDesc}}</div>
                <div class="mb8">投诉原因：{{item.reason}}</div>
                <div class="mb8" v-if="item.ctype">赔付方式：{{item.ctype}}</div>
                <div class="mb8" v-for="(correct, i) in item.correctInfos" :key="i">{{correct}}</div>
                <template v-if="listType == 1 && item.auditResult">
                  <div class="mb8">驳回原因：{{item.auditResult.auditReason}}</div>
                  <div class="mb8">驳回时间：{{item.auditResult.auditTime}}</div>
                </template>
                
              </div>
              <div class="flex items-center" v-if="listType == 0">
                <el-button type="primary" @click="handlePass(item.cid)">通过</el-button>
                <el-button type="danger" @click="$refs.refusePopupRef.open({cid: item.cid})">驳回</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="claimList && claimList.length > 0">
        <Pagination :currentPage="page.current + 1" @next-click="nextPage" @prev-click="prevPage"></Pagination>
      </div>
    </div>

    <!-- 职位详情 -->
    <PostDetailPopup ref="postDetailRef"></PostDetailPopup>
    <!-- 拒绝赔付 -->
    <RefusePopup ref="refusePopupRef" @update="getClaimList"></RefusePopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import LoadImage from '@/components/LoadImage'
import PostDetailPopup from '@/components/PostDetailPopup'
import RefusePopup from './components/RefusePopup.vue'
import { claimRecListApi, auditClaimApi } from '@/api/admin.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'ClaimList',
  components: {
    NavTitle,
    Pagination,
    LoadImage,
    PostDetailPopup,
    RefusePopup
  },
  data() {
    return {
      menuList: [
        {text: '待审核', type: '0'},
        {text: '已驳回', type: '1'},
        {text: '已赔付', type: '2'}
      ],
      claimList: [],
      listType: '0',
      page: {
        current: 0,
        size: 10
      },
      seachWord: '',
      screenDate: [],
      claimStatics: null
    }
  },
  created() {
    this.getClaimList()
  },
  methods: {
    menuSelect(val) {
      this.listType = val
      this.page.current = 0
      this.getClaimList()
    },
    getClaimList() {
      const formData = {
        startPage: this.page.current - 1 < 0 ? 0 : this.page.current,
        status: Number(this.listType)
      }
      if(this.screenDate && this.screenDate.length > 0) {
        Object.assign(formData, {
          startTime: this.screenDate[0],
          endTime: this.screenDate[1]
        })
      }
      if(this.seachWord.trim()) {
        Object.assign(formData, {
          mid: this.seachWord.trim()
        })
      }
      console.log('formData', formData)
      claimRecListApi(formData).then(res => {
        console.log('赔付列表', res)
        this.claimStatics = res.data.claimStatics
        const list = res.data.adminClaimRecLists || []
        if(list.length != 0 || this.page.current == 0) {
          list.forEach(item => {
            if(item.reporterLogo?.startsWith('oss')) {
              ossKeyToUrl({key: item.reporterLogo}).then(ossRes => {
                item.reporterLogo = ossRes.url
              })
            }
            if(item.evidence?.length > 0) {
              item.evidence.forEach((ev, index) => {
                ossKeyToUrl({key: ev}).then(ossRes => {
                  this.$set(item.evidence, index, ossRes.url)
                })
              })
            }
          })
          this.claimList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    handlePass(cid) {
      this.$confirm('确定通过审核吗？').then(action => {
        if(action == 'confirm') {
          const formData = {
            act: 1,
            cid
          }
          console.log('formData', formData)
          auditClaimApi(formData).then(res => {
            console.log(res)
            this.$tips({message: res.msg, type: 'success'})
            this.getClaimList()
          }).catch(err => {
            this.$tips({message: err, type: 'error'})
          })
        }
      }).catch(err => {})
    },
    handleSearch() {
      this.page.current = 0
      this.getClaimList()
    },
    nextPage() {
      this.page.current++
      this.getClaimList()
    },
    prevPage() {
      this.page.current = this.page.current > 0 ? this.page.current - 1 : 0
      this.getClaimList()
    },
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
.statics {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  background-color: #fff;
  padding: 16px;
  text-align: center;
  .num {
    font-size: 18px;
    font-weight: bold;
    margin-top: 16px;
  }
}
.paid-item {
  background-color: #fff;
  padding: 24px;
  .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F5F5F5;
    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 8px;
    }
    .time {
      color: #666;
    }
  }
  .enter-arrow {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: var(--theme-color);
    cursor: pointer;
    margin-left: 10px;
    img {
      width: 13px;
      height: 13px;
    }
  }
  .info-row {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 16px;
    margin-top: 12px;
  }
  .img-list {
    display: flex;
    .img {
      width: 56px;
      height: 56px;
      border-radius: .06rem;
      margin-left: 10px;
    }
  }
  .reason {
    color: var(--theme-color-red);
    margin-top: 12px;
  }
}
</style>